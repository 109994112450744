import Vue from 'vue'
import Vuex from 'vuex'
import {
    setProp,
    deleteProp,
    arrToObj,
    $api
} from 'bh-mod'


Vue.use(Vuex)

export default {
    state: {
        editElevationCallback: null,
        updatedKey: Date.now(),
        units: {},
        unitGroups: {},
        unitTypes: [],
        tags: {},
        editModel: {},
        editElevation: {},
        editDrawer: false,
        newDrawer: false,
        introPage: false,
        currentModel: {},
        getStarted: false,
        showingModel: {
            id: ''
        },
        showingType: {},
        newModelTemp: {},
        modelDialog: {
            show: false,
            type: 'add'
        },
        elevationDialog: {
            show: false,
            type: 'add'
        },
        unitTypeDialog: {
            show: false,
            type: 'add'
        },
        showingFilter: 'all',
        allSettings: {
            app:{
                options:{}
            }
        },
        orderedModels: [],
        currentType: '',
        display: 'block',
        crumbs: [],
        selectedArray: [],
        customField:{
            visible:false,
            id:''
        },
        deleteModelType:false
    },
    mutations: {
        DEL_MODEL_TYPE(state) {
            state.deleteModelType = true
        },
        FINISHDEL_MODEL_TYPE(state) {
            state.deleteModelType = false
        },
        ADD_FIELD(state) {
            state.customField.visible = true
        },
        CLOSE_FIELD(state) {
            state.customField.visible = false
            if (state.customField.id != '') state.customField.id = ''
        },
        EDIT_FIELD(state, data) {
            state.customField.visible = true
            state.customField.id = data
        },
        SELECTED_ARRAY(state, data) {
            state.selectedArray = data
        },
        UPDATE_SETTINGS(state, data) {
            if (!state.allSettings.app) state.allSettings.app = {options:{}}
            state.allSettings.app.options = Object.assign(state.allSettings.app.options, data.options)
        },
        CHANGE_TYPE(state, data) {
            Object.values(state.unitGroups).forEach(unit => {
                if (unit.type == data.from.type && unit.size == data.from.size) {
                    unit.type = data.to.type
                    unit.size = data.to.size
                }
            })
        },
        SET_CRUMBS(state, data) {
            state.crumbs = data
        },
        CHANGE_DISPLAY(state, data) {
            state.display = data
        },
        changeType(state, data) {
            console.log('CHANGING TYPE', data)
            state.currentType = data
        },
        REORDER(state, data) {
            state.orderedModels = data
        },
        UPDATE_EDIT(state, data) {
            state.unitGroups[data.id] = data
        },
        SET_SETTINGS(state, data) {
            let temp = data
            if (data.userApp == null) {
                temp.userApp = {
                    options: {
                        seenIntro: false,
                    }
                }
            }
            if (data.app == null) {
                temp.app = {
                    options: {
                        customFields: [],
                    }
                }
            }
            state.allSettings = temp
        },
        SET_PROP: (state, {
            where,
            what,
            del = false,
            update = false
        }) => {
            if (del) return deleteProp(state, where)
            state.newStoreTime = Date.now()
            return setProp(state, where, what)
        },
        SAVE_MODEL: (state, newModel) => {
            state.unitGroups[newModel.id] = newModel
        },
        SAVE_TEMP_MODEL: (state, newModel) => {
            state.newModelTemp = newModel
        },
        INTRO_PAGE: (state) => {
            state.introPage = true
        },
        CLOSE_INTRO: (state) => {
            state.introPage = false
        },
        SET_TYPES: (state, data) => state.unitTypes = data,
        UPDATE_ELEVATION: (state, data) => {
            // if (state.editElevationCallback) state.editElevationCallback(data)
            // state.editElevationCallback = null
            data.tags.forEach(tag => {
                if (!state.tags[tag.id]) state.tags[tag.id] = tag
            })
            if (!data.connected) data.connected = []
            data.connected = data.connected.map(cn => cn.id ? cn.id : cn)
            state.unitGroups[data.unitGroup.id].units = state.unitGroups[data.unitGroup.id].units.map(x => {
                if (x.id === data.id) return data
                return x
            })
            setProp(state, ['units',data.id], data)
            console.log('STATE UNITS CHECK PACKAGES', state.units, data.id)
            state.updatedKey = Date.now()

        },
        DELETE_ELEVATION: (state, data) => {
            let findIndex = state.unitGroups[data.unitGroup.id].units.findIndex(x => x.id == data.id)
            if (findIndex != -1) {
                state.unitGroups[data.unitGroup.id].units.splice(findIndex, 1)
            }
        },

        ADD_MODEL: (state, data) => {
            state.unitGroups[data.id] = data
                // state.showingType = data.unitType
        },
        DUPLICATE_MODEL: (state, data) => {
            let newData = JSON.parse(JSON.stringify(data))
            let unitGroups = JSON.parse(JSON.stringify(state.unitGroups))
            let units = newData.units
            unitGroups[data.id] = newData
            unitGroups[data.id].units = []
            units.forEach(x => {
                if (!state.units.hasOwnProperty(x.id)){
                    console.log('ADDING NEW UNIT', x)
                    state.units[x.id] = x
                    unitGroups[data.id].units.push(x)
                }
            })
            console.log('UNIT GROUPS', unitGroups)
            state.unitGroups = unitGroups
                // state.unitGroups[data.id] = data
        },
        UPDATE_MODEL: (state, {
            id,
            backView,
            frontView,
            image,
            name,
            status,
            unitType
        }) => {

            let modelIndex = state.unitGroups.findIndex(x => x.id === id)

            state.unitGroups[modelIndex].backView = backView
            state.unitGroups[modelIndex].frontView = frontView
            state.unitGroups[modelIndex].image = image
            state.unitGroups[modelIndex].name = name
            state.unitGroups[modelIndex].status = status
            state.unitGroups[modelIndex].unitType = unitType
            state.showingType = unitType

        },
        DELETE_MODEL: (state, data) => {
            SET_PROP
            // delete state.unitGroups[data.id]

        },
        ADD_ELEVATION: (state, data) => {
            console.log('fromstore', data)

            data.tags.forEach(tag => {
                if (!state.tags[tag.id]) state.tags[tag.id] = tag
            })
                // data.tags = data.tags.map(x => {
                //   let found = Object.values(state.tags).find(y => y.name === x.name)
                //   if (!found) Object.values(state.tags).push(x)
                //   return x.id
                // })

            // let modelIndex = state.unitGroups.findIndex(x => x.id === data.unitGroup.id)
            let id = ''
            if (typeof data.unitGroup == 'object') id = data.unitGroup.id
            else if (typeof data.unitGroup == 'string') id = data.unitGroup
			if (!state.unitGroups[id].hasOwnProperty('units')) state.unitGroups[id].units = []
			else state.unitGroups[id].units = state.unitGroups[id].units
            state.unitGroups[id].units.push(data)
            setProp(state, ['units',data.id], data)

        },
        SET_APPDATA(state, data) {
            if (data.units && data.units.length) {

                data.units.forEach(elevation => {
                    if (elevation.tags) {
                        elevation.tagIDs = elevation.tags.map(x => x.id)
                    }
                })

            }

            Object.entries(data).forEach(([key, value]) => {
                state[key] = arrToObj(value)
            })

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
        SHOW_EDIT_TYPES: (state, model) => {
            state.unitTypeDialog = {
                show: true,
                type: 'edit'
            }
            state.editModel = model
        },
        CLOSE_DIALOG: state => {
            state.modelDialog.show = false
            state.unitTypeDialog.show = false
            state.elevationDialog.show = false
            state.updatedKey = Date.now()
        },
        CHECK_ADD_TAGS: (state, data) => {
            let tags = state.tags.map(x => x.id)
            data.forEach(newTag => {
                if (!tags.includes(newTag.id))
                    state.tags.push(newTag)
            })

        },
        SHOW_ADD_MODEL: state => {
            state.modelDialog = {
                show: true,
                type: 'add'
            }
        },
        SHOW_EDIT_MODEL: (state, model) => {
            state.modelDialog = {
                show: true,
                type: 'edit'
            }
            state.editModel = model
        },
        SHOW_ADD_ELEVATION: state => {
            state.elevationDialog = {
                show: true,
                type: 'add'
            }
        },
        SHOW_EDIT_ELEVATION: (state, {
            ele,
            callback
        }) => {
            state.elevationDialog = {
                show: true,
                type: 'edit'
            }
            state.editElevation = ele
            state.editElevationCallback = callback
        },
        SHOW_MODEL: (state, model) => {
            state.showingModel = model
        },
        SHOW_TYPE: (state, type) => {
            state.showingType = type
            if (type.models && type.models.length)
                state.showingModel = type.models[0]
            else
                state.showingModel = {}
        },
        UNPUBLISH_MODELS: (state, array) => {
            console.log('unpublishing models')
            array.forEach(id => state.unitGroups[id].status = 'draft')
        },
        PUBLISH_MODELS: (state, array) => {
            console.log('publishing models')
            array.forEach(id => state.unitGroups[id].status = 'published')
        },
        DELETE_MODELS: (state, arr) => {
            arr.forEach(x => deleteProp(state.unitGroups, x))
        },
        MOVE_MODELS: (state, data) => {
            // console.log('data from store', data)
            data.ids.forEach(id => {
                if (state.unitGroups[id]) {
                    state.unitGroups[id].type = data.type
                    state.unitGroups[id].size = data.size
                }
            })
        }
    },
    actions: {
        DUPLICATE_ELEVATION({commit}, data) {
            commit('SET_PROP', {
                where:['units', data.id],
                what:data,
                update:true
            })
			commit('ADD_ELEVATION', data)
        },
        DELETE_ELEV:({commit}, data) => {
            commit('SET_PROP', {
                where:['units', data],
                del:true
            })
        },
        DELETE_MODEL:({commit}, data) => {
            commit('SET_PROP', {
                where:['unitGroups', data],
                del:true
            })
        },
        DELETE_MODELS: ({
            commit,
            rootState
        }, arr) => {
            return new Promise((resolve, reject) => {
                $api.put(`/units/${rootState.instance.id}/deleteModels`, arr).then(({
                    data
                }) => {
                    if (data.success) {
                        arr.forEach(id => commit('SET_PROP', {
                            where: ['unitGroups', id],
                            del: true
                        }))
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }).catch((err) => {
                    resolve(false)
                })
            })

        },
        EDIT_ELEVATION: ({commit}, data) => {
            commit('EDIT_ELEVATION', data)
        },
        SET_APPDATA: async({
            state,
            commit,
            dispatch
        }, data) => {

            if (data) {
                commit('SET_APPDATA', data)
            }
        },
        closeDialog({commit}, {type,data}) {

            if (type.includes('unitTypes')) {
                commit('SET_TYPES', data)
            } else if (type.includes('model')) {
                if (type.includes('add')) {
                    commit('ADD_MODEL', data)
                } else if (type.includes('edit')) {
                    commit('UPDATE_MODEL', data)
                } else if (type.includes('delete')) {
                    commit('DELETE_MODEL', data)
                }
            } else if (type.includes('elevation')) {
                if (type.includes('add')) {
                    commit('ADD_ELEVATION', data)
                } else if (type.includes('edit')) {
                    commit('UPDATE_ELEVATION', data)
                } else if (type.includes('delete')) {
                    commit('SET_PROP', {
                        where:['units', data.id],
                        del:true
                    })
                }
            }
            commit('CLOSE_DIALOG')
        },
        UNPUBLISH({
            commit,
            rootState
        }, {
            items,
            type
        }) {
            return new Promise((resolve, reject) => {
                let url = `/units/${rootState.instance.id}/updateModelsStatus`
                if (type === 'elevation') url = `/units/${rootState.instance.id}/updateUnitsStatus`

                $api.put(url, items).then(({
                    data
                }) => {
                    console.log('dataaaaa', data)
                    if (data.success) {
                        if (items.status == 'draft') commit('UNPUBLISH_MODELS', items.ids)
                        if (items.status == 'published') commit('PUBLISH_MODELS', items.ids)
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }).catch(() => {
                    resolve(false)
                })


            })


        }
    },
    getters: {
        appData: state => state,
        unitTypes: state => {
            let unitList = [{
                label: 'All Home Models',
                value: 'allhomemodels',
                type: '',
                size: '',
                options: [],
                children: [],
                fixed: true,
				iconUrl: require("@/assets/sideIcons/allhomemodels.svg")
            }]
            Object.values(state.unitGroups).forEach(model => {
                let mod = {
                    label: '',
                    value: '',
                    type: '',
                    size: '',
                    options: [{
                            label: 'Delete Home Model Type',
                            value: 'delete'
                        },
                        {
                            label: 'Unpublish Home Model Type',
                            value: 'unpublish'
                        },
                        {
                            label: 'Edit Home Model Type',
                            value: 'edit'
                        }
                    ],
                    children: [],
                }
                mod.label = `${model.size}'` + ` ${model.type}`
                mod.value = `${model.size}'` + ` ${model.type}`
                mod.type = model.type
                mod.size = model.size
                let index = unitList.findIndex(x => x.label == mod.label)


                let child = {
                    label: '',
                    value: '',
                    parents: '',
                    beds: '',
                    baths: '',
                    garages: '',
                    stories: '',
                    sqft: '',
                }
                child.label = model.name
                child.value = model.id
                child.parent = `${model.type}` + ` ${model.size}`
                if (index == -1) {

                    mod.children.push(child)
                    unitList.push(mod)

                } else {
                    unitList[index].children.push(child)
                }
            })
            let tempList = []
            unitList.forEach(x => {
                if (x.value != 'allhomemodels') {
                    tempList.push(x)
                }
            })
            tempList.sort((a, b) => {
                if (a.label > b.label) return 1
                else return -1

            })
            unitList = [unitList[0]].concat(tempList)
            let divider = {
                divider: true
            }
            let customFields = {
                label: 'Settings',
                value: 'settings',
                type: '',
                size: '',
                options: [],
                children: [],
                fixed: true,
                iconUrl: require("@/assets/sideIcons/settings.svg")
            }
            unitList.push(divider)
            unitList.push(customFields)
            return unitList

            // return unitList
        },
        unitTypesFinal: (state, getters) => {
            let unitTypes = getters.unitTypes
            let orderedTypes = JSON.parse(JSON.stringify(state.orderedModels))
            if (!orderedTypes.length) return unitTypes

            let labels = orderedTypes.map((({ type, size }) => `${type.toLowerCase()}${size}`))
            unitTypes.forEach(uT => {
                let { type, size } = uT
                let key = `${type.toLowerCase()}${size}`
                if (labels.includes(key)) return
                orderedTypes.push(uT)
            })

            return orderedTypes

        },
        unitTypeList: (state) => {
            let unitTypeList = {}
            Object.values(state.unitGroups).forEach(uG => {
                let label = `${uG.size}'` + ` ${uG.type}`
                let name = `${uG.size}'` + ` ${uG.type}`
                let type = uG.type
                let size = uG.size
                if (!unitTypeList[label]) unitTypeList[label] = { label, children: [], name, type, size }
                unitTypeList[label].children.push(uG)
                unitTypeList[label].children.forEach(x => {
                    let units = Object.values(state.units).filter(y => y.unitGroup).filter(y => y.unitGroup.id ?  y.unitGroup.id === x.id : x.unitGroup ===  x.id)
                    x.units = units
                })
            })

            let names = Object.keys(unitTypeList)
            let list = Object.values(unitTypeList)
            list.forEach(uG => {
                uG.children.forEach(model => {
                    let beds = []
					let dens = []
                    let baths = []
					let powderRooms = []
                    let garages = []
					let stories = []
                    let sqft = []
                    let price = []
                    model.units.forEach(ele => {
                        beds.push(ele.packages && ele.packages[0] && ele.packages[0].beds || 0)
						dens.push(ele.packages && ele.packages[0] && ele.packages[0].dens || 0)
                        baths.push(ele.packages && ele.packages[0] && ele.packages[0].baths || 0)
						powderRooms.push(ele.packages && ele.packages[0] && ele.packages[0].powderRooms || 0)
                        garages.push(ele.packages && ele.packages[0] && ele.packages[0].garages || 0)
						stories.push(ele.packages && ele.packages[0] && ele.packages[0].stories || 0)
                        sqft.push(ele.packages && ele.packages[0] && ele.packages[0].sqft || 0)
                        price.push(ele.packages && ele.packages[0] && ele.packages[0].price || 0)
                    })
                    model.minBed = Math.min(...beds)
                    model.maxBed = Math.max(...beds)
					model.minDen = Math.min(...dens)
					model.maxDen = Math.max(...dens)
                    model.minBath = Math.min(...baths)
                    model.maxBath = Math.max(...baths)
					model.minPowderRoom = Math.min(...powderRooms)
					model.maxPowderRoom = Math.max(...powderRooms)
                    model.minGarage = Math.min(...garages)
                    model.maxGarage = Math.max(...garages)
					model.minStories = Math.min(...stories)
					model.maxStories = Math.max(...stories)
                    model.minSqft = Math.min(...sqft)
                    model.maxSqft = Math.max(...sqft)
                    model.minPrice = Math.min(...price)
                    model.maxPrice = Math.max(...price)
                })
            })
            names.sort((a, b) => {
                if (a > b) return 1
                else return -1
            })
            let orderList = {}
            names.forEach(x => {
                orderList[x] = unitTypeList[x]
            })
            return orderList
        }
    },
}
