<template>
    <div class="w-full h-full dF fC aS" style="display: inline-block">
        <a-modal
            v-if="changeType && changeType.from"
            :visible="visible"
            @cancel="onClose"
            :centered="true"
            :footer="false"
        >
            <bh-loading :show="loading" />
            <h5>Edit Model Type</h5>
            <div>
                What do you want to change Home Model Type
                <strong>{{
                    `${changeType.from.type} ${changeType.from.size} `
                }}</strong
                >to?
            </div>
            <a-form-model class="mt-3" :model="changeType.to" ref="typeInfo">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item
                            required
                            label="Frontage (Only Digits)"
                            prop="size"
                            :rules="req('Please fill in the Frontage')"
                        >
                            <a-input
                                type="number"
                                v-model="changeType.to.size"
                                :placeholder="changeType.from.size"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item
                            required
                            label="Model Type"
                            prop="type"
                            :rules="req('Please fill in the Model Type')"
                        >
                            <a-input
                                v-model="changeType.to.type"
                                :placeholder="changeType.from.type"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div class="w-full dF jE">
                <a-button
                    size="large"
                    class="mr-3 cancel-button"
                    @click="onClose"
                    >CANCEL</a-button
                >
                <a-button size="large" type="primary" @click="submitType"
                    >SAVE CHANGES</a-button
                >
            </div>
        </a-modal>
        <LeftNavBar
            :selectedValue="$store.state.models.currentType"
            :drag="true"
            @order="reorderItems"
            :showAllModel="true"
            @showAll="modelPageStart"
            :title="selectedAppInitial"
            :imgSrc="'https://bildhive.nyc3.digitaloceanspaces.com/appIcons/home-model-asset.svg'"
            :items="unitTypeList"
            @opened="unitTypeClicked"
            @optionclick="moreOptions"
        ></LeftNavBar>
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
import bhLoading from "bh-mod/components/common/Loading";
export default {
    components: {
        LeftNavBar,
        bhLoading,
    },
    name: "left-sidebar",
    data() {
        return {
            categorizedList: [],
            tabModels: [],
            sort: false,
            deleteObj: {},
            unitList: [],
            unpublishObj: {},
            orderItems: [],
            stack: [],
            visible: false,
            changeType: { from: {}, to: {} },
            loading: false,
        };
    },
    watch: {
        orderItems: {
            handler(val) {
                this.$store.commit("REORDER", val);
            },
        },
        openID(val) {
            if (this.categorized[val]) {
                this.tabModels = this.categorized[val].models;
            }
        },
        categorized: {
            handler(val) {
                this.categorizedList = Object.values(val);
                if (this.openID) {
                    this.tabModels = this.categorized[this.openID].models;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "HMC"
            );
        },
        unitTypeList() {
            return this.$store.getters.unitTypesFinal;
        },
        openID() {
            return this.$store.state.models.showingType.id;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
        unitTypes() {
            return this.$store.state.models.unitTypes;
        },
        unitGroups() {
            return this.$store.state.models.unitGroups;
        },
        categorized() {
            let sort = this.sort;
            let list = [];
            let types = this.unitTypes;
            let models = this.unitGroups || [];
            if (!types.length) return {};
            let result = {
                uncat: {
                    name: "Uncategorized",
                    id: "uncat",
                    models: [],
                },
            };
            types.forEach((x) => {
                //x.models = []
                //result[x.id] = x
                list.push(x);
            });
            if (sort == false) {
                list.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name == b.name) {
                        if (a.size > b.size) return 1;
                        return -1;
                    } else {
                        return -1;
                    }
                });
            } else {
                list.sort((a, b) => {
                    if (a.name > b.name) {
                        return -1;
                    } else if (a.name == b.name) {
                        if (a.size > b.size) return -1;
                        return 1;
                    } else {
                        return 1;
                    }
                });
            }

            list.forEach((x) => {
                x.models = [];
                result[x.id] = x;
            });

            models.forEach((x) => {
                let typeID =
                    (x.unitType &&
                        types.find((type) => type.id === x.unitType.id) &&
                        x.unitType.id) ||
                    "uncat";
                if (!result[typeID]) result[typeID];
                result[typeID].models.push(x);
            });
            return result;
        },
        showingModel() {
            return this.$store.state.models.showingModel;
        },
        instance() {
            return this.$store.state.instance;
        },
    },
    methods: {
        submitType() {
            // let ref='changeType.to'
            this.$refs.typeInfo.validate((validate) => {
                if (validate) {
                    this.changeType.from.size = Number(
                        this.changeType.from.size
                    );
                    this.changeType.to.size = Number(this.changeType.to.size);
                    this.loading = true;
                    this.$api
                        .put(
                            `/unit-groups/${this.instance.id}`,
                            this.changeType
                        )
                        .then(({ data }) => {
                            this.loading = false;
                            if (data.success) {
                                this.$store.commit(
                                    "CHANGE_TYPE",
                                    this.changeType
                                );
                                if (
                                    this.$route.path.includes("/showmodel") &&
                                    this.$route.params.value ==
                                        `${this.changeType.from.type} ${this.changeType.from.size}` &&
                                    this.$route.params.value &&
                                    this.$route.params.values !=
                                        `${this.changeType.to.type} ${this.changeType.to.size}`
                                ) {
                                    let newPath = `${this.changeType.to.type} ${this.changeType.to.size}`;
                                    this.$router.push(`/showmodel/${newPath}`);
                                    this.$store.commit("SET_CRUMBS", [
                                        `${this.changeType.to.size}' ${this.changeType.to.type}`,
                                    ]);
                                    this.$store.commit("changeType", newPath);
                                }
                                this.onClose();
                            }
                        });
                }
            });
        },
        req: (msg) => ({ required: true, message: msg }),
        onClose() {
            this.visible = false;
            this.$refs.typeInfo.resetFields();
        },
        reorderItems(e) {
            this.orderItems = [...this.unitTypeList];
        },
        confirmDelete() {
            let deleteArray = [];
            let [size, type] = this.deleteObj.itemValue.split(`' `);

            for (var i in this.unitGroups) {
                if (
                    type === this.unitGroups[i].type &&
                    +size === this.unitGroups[i].size
                ) {
                    deleteArray.push(this.unitGroups[i]);
                }
            }
            let deleteIndex = this.unitTypeList.findIndex((x) => {
                return x.label === this.deleteObj.itemValue;
            });

            this.unitTypeList.splice(deleteIndex, 1);
            let ids = deleteArray.map((x) => x.id);
            this.$store.dispatch("DELETE_MODELS", ids);
			if(this.$route.path !== "/showmodel") {
				this.$router.push("/showmodel")
			}
        },
        async confirmUnpublish() {
            let unpublishArray = [];
            let [size, type] = this.unpublishObj.itemValue.split(`' `);
            for (var i in this.unitGroups) {
                if (
                    type === this.unitGroups[i].type &&
                    +size === this.unitGroups[i].size
                ) {
                    unpublishArray.push(this.unitGroups[i]);
                }
            }
            let ids = unpublishArray.map((x) => x.id);
            let isDone = await this.$store.dispatch("UNPUBLISH", {
                type: "models",
                items: { ids: ids, status: "draft" },
            });
            if (isDone) {
                this.visible = false;
            } else {
                this.$message.error("SOMETHING HAPPENED");
            }
        },
        moreOptions(data) {
            if (this.$p < 40)
                return this.$message.error(
                    "You do not have permission to perform this action"
                );

            if (data.optionValue == "delete") {
                this.deleteObj = data;
                let self = this;
                this.$confirm({
                    title: "Delete Home Model Type",
                    content: (h) => (
                        <div>
                            Do you want to delete this Home Model Type. Deleting
                            will permanently remove all the Models in this Home
                            Model Type
                        </div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.$store.commit("DEL_MODEL_TYPE");
                        self.confirmDelete();
                    }
                });
            }
            if (data.optionValue == "unpublish") {
                this.unpublishObj = data;
                let self = this;
                this.$confirm({
                    title: "Unpublish",
                    content: (h) => (
                        <div>
                            Do you want to unpublish this Home Model Type.
                            Unpublishing will permanently unpublish the all the
                            Models in this Home Model Type
                        </div>
                    ),
                    okText: "Unpublish",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.confirmUnpublish();
                    }
                });
            }
            if (data.optionValue == "edit") {
                let [size, type] = data.itemValue.split(`' `);
                size = +size;
                this.changeType.from = { type, size };
                this.changeType.to = { type: "", size: "" };
                this.visible = true;
            }
        },
        modelPageStart(data) {
            if (data == true) {
				if(this.$route.path !== "/showmodel") {
					this.$router.push("/showmodel")
				}
            } else {
                this.$router.push("/modeldetails");
            }
        },
        unitTypeClicked(data) {
            if (data.value.trim() == "allhomemodels") {
                if (
                    this.$route.params.value ||
                    !this.$route.path.includes("/showmodel")
                ) {
                    this.$router.push(`/showmodel`);
                    // this.$store.commit('SET_CRUMBS', ['All Home Models'])
                }
            } else if (data.value.trim() == "settings") {
                if (
                    this.$route.params.value ||
                    !this.$route.path.includes("/settings")
                ) {
                    this.$router.push(`/settings`);
                    // this.$store.commit('SET_CRUMBS', ['Settings'])
                }
            } else {
                if (this.$route.params.value != data.value && !this.$route.path.includes(`/showmodel/${data.value}`)) {
                    this.$router.push(`/showmodel/${data.value}`);
                    // this.$store.commit('SET_CRUMBS', [data.value])
                }
            }
        },
        updateIds(e) {
            let ids = this.tabModels.map((x) => x.id);

            this.$api
                .put(
                    `/units/${this.$store.state.instance.id}/unitGroups/arrange-ids`,
                    ids
                )
                .then(() => {
                    // this.$store.commit('REORDER_LOTS', ids)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        showModel(item) {
            //just added
            this.$store.commit("SHOW_MODEL", item);
        },
        //console types and models
        showType(cat) {
            if (this.openID === cat.id) {
                // this.openID = ''
            } else {
                this.$store.commit("SHOW_TYPE", cat);
            }
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
    created() {
    },
};
</script>

<style lang="scss">
.uncategorized {
    background: var(--light-purple);
}
.sorting-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}
.sorting-button:hover {
    color: #777;
}
</style>
