<!-- edit this file-->
<template>
    <div
        :class="$style.subbar"
        v-if="instance"
        style="background-color: rgb(247, 245, 249)"
    >
        <div class="searchbar--app">
            <div :class="$style.breadcrumbs" class="dF aC breadcrumbs">
                <a-breadcrumb class="dF">
                    <a-breadcrumb-item>{{ instance.name }}</a-breadcrumb-item>
                    <a-breadcrumb-item
                        :style="crumbs.length == 0 ? 'color:#F7941E' : ''"
                        >{{ selectedAppInitial }}</a-breadcrumb-item
                    >
                    <a-breadcrumb-item
                        :style="
                            crumbI == crumbs.length - 1 ? 'color:#F7941E' : ''
                        "
                        v-for="(crumb, crumbI) in crumbs"
                        :key="crumbI"
                        >{{ crumb }}</a-breadcrumb-item
                    >
                </a-breadcrumb>
            </div>

            <div :class="$style.amount" class="ml-auto d-none d-sm-flex aC">
                <a-auto-complete
                    v-show="
                        $route.meta.title == 'Display Home Models' ||
                        $route.meta.title == 'Custom Fields'
                    "
                    style="width: 300px"
                    placeholder="Search for a home model..."
                    :dropdown-match-select-width="false"
                    :dropdown-style="{ width: '300px' }"
                    option-label-prop="value"
                    @search="handleSearch"
                    class="mr-4"
                    size="large"
                >
                    <template slot="dataSource">
                        <!-- <a-select-opt-group> -->
                        <a-select-option
                            @click="optionClicked(model.id)"
                            v-for="(model, modelI) in searchResult"
                            :key="modelI + model.id"
                            :value="model.name"
                        >
                            <div class="dF aC">
                                <span class="mr-2">
                                    <img
                                        v-if="
                                            model &&
                                            model.image &&
                                            model.image != ''
                                        "
                                        style="
                                            width: 30px;
                                            height: 30px;
                                            object-fit: cover;
                                        "
                                        :src="`${model.image}`"
                                    />
                                    <img
                                        v-else
                                        style="
                                            width: 30px;
                                            height: 30px;
                                            object-fit: cover;
                                        "
                                        src="@/assets/no-image.png"
                                    />
                                </span>
                                <div class="f1">
                                    <div>
                                        <strong>{{ model.name }}</strong>
                                    </div>
                                    <small class="text-sm"
                                        >{{
                                            model.units.length
                                        }}
                                        elevation(s)</small
                                    >
                                </div>
                            </div>
                        </a-select-option>
                        <!-- </a-select-opt-group> -->
                    </template>
                    <a-input>
                        <a-icon
                            style="stroke: #000; stroke-width: 34"
                            slot="prefix"
                            type="search"
                        />
                    </a-input>
                </a-auto-complete>

                <div class="mr-4 d-none d-xl-block dash-divider"></div>
                <a-button
                    v-show="
                        $route.meta.title == 'Display Home Models' ||
                        $route.meta.title == 'Custom Fields'
                    "
                    @click="addModel"
                    type="primary"
                    icon="plus"
                    size="large"
                >
                    ADD NEW HOME MODEL
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { getAppIcon, getInitial } from "bh-mod";
export default {
    data() {
        return {
            searchResult: [],
        };
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "HMC"
            );
        },
        crumbs() {
            return this.$store.state.models.crumbs;
        },
        instance() {
            return this.$store.state.instance;
        },
        showingModel() {
            return this.$store.state.models.showingModel;
        },
        unitGroups() {
            return this.$store.state.models.unitGroups;
        },
    },
    watch: {
        searchResult: {
            async handler(val) {
                if (val.length) {
                    console.log(val);
                    let id = val[0].id;
                    if (this.$store.state.models.display == "block") {
                        await document
                            .querySelectorAll(".select-block")
                            .forEach((x) => x.classList.remove("select-block"));
                        if (
                            document.querySelector(`[card-data-value="${id}"]`)
                        ) {
                            document
                                .querySelector(`[card-data-value="${id}"]`)
                                .classList.add("select-block");
                        }
                    } else if (this.$store.state.models.display == "list") {
                        await document
                            .querySelectorAll(".select-list")
                            .forEach((x) => x.classList.remove("select-list"));
                        if (
                            document.querySelector(`[list-data-value="${id}"]`)
                        ) {
                            console.log("found thisssss");
                            document
                                .querySelector(`[list-data-value="${id}"]`)
                                .classList.add("select-list");
                        }
                    }
                }
            },
        },
    },
    methods: {
        handleSearch(input) {
            if (input && input.length > 1) {
                this.searchResult = Object.values(this.unitGroups).filter(
                    ({ name, type, size }) => {
                        name = name.toLowerCase();
                        type = type.toLowerCase();
                        size = size.toString();
                        input = input.toLowerCase();
                        return (
                            name.includes(input) ||
                            type.includes(input) ||
                            size.includes(input)
                        );
                    }
                );
            } else {
                return (this.searchResult = []);
            }
        },
        getAppIcon,
        addModel() {
            this.$router.push("/modeldetails");
            this.$store.commit("SET_CRUMBS", []);
        },
        getAppIcon,
        getInitial,
        filterOption(input, option) {
            console.log("INPUT", input);
            console.log("OPTIONS", option);
            // return (
            //   option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
            // );
        },
        optionClicked(data) {
            console.log("data", data);
            this.$router.push(`/modeldetails/${data}`);
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
    .searchbar--app {
        display: flex;
    }
}

@media screen and (max-width: 1030px) {
    .breadcrumbs {
        margin-bottom: 10px;
    }
}

/* .ant-select-dropdown-menu-item {
    height: 100px;
  } */
</style>

<style lang="scss">
.ant-card.ant-card-bordered.select-block {
    border: none;
    -webkit-animation-name: setBorder;
    -webkit-animation-iteration-count: 2s;
    -webkit-animation-duration: 2s;
}
@-webkit-keyframes setBorder {
    100% {
        border: 2px solid var(--orange);
    }
}
.select-list {
    background-color: #fff;
    -webkit-animation-name: setBackgroundColor;
    -webkit-animation-iteration-count: 2s;
    -webkit-animation-duration: 2s;
}
@-webkit-keyframes setBackgroundColor {
    100% {
        border: 2px solid var(--orange);
    }
}
</style>
