import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import App from './App.vue'
import router from './router'
import store from './store'
import NProgress from 'vue-nprogress'
import { i18n } from 'bh-mod/localization'
import BootstrapVue from 'bootstrap-vue'
import { $api, DBURL, parseError, initSentry, refreshToken } from 'bh-mod'
import VuePapaParse from 'vue-papa-parse'

initSentry(router)

import {
	Avatar, TreeSelect, Rate, Breadcrumb, InputNumber, Steps, Message,
	Upload, Button, Layout, Table, Icon, Progress, Radio, Dropdown, Menu,
	Input, Badge, Form, Tooltip, Select, Switch,
	Tag, Affix, Spin, Alert, Checkbox, Tabs, Pagination, notification, Drawer,
	Cascader, DatePicker, TimePicker, Divider, Anchor, AutoComplete, Card, List, Popover,
	Tree, Timeline, Row, Col, Transfer, Modal, message, Popconfirm, Skeleton, LocaleProvider, FormModel
} from 'ant-design-vue'
import PortalVue from 'portal-vue'
import 'bh-mod/scss/global.scss'

var jwtDecode = require('jwt-decode');


Vue.use(PortalVue)
Vue.use(VuePapaParse)
Vue.use(BootstrapVue)
Vue.use(FormModel)
Vue.use(Skeleton)
Vue.use(Avatar)
Vue.use(Popconfirm)
Vue.use(Transfer)
Vue.use(Modal)
Vue.use(Divider)
Vue.use(Row)
Vue.use(Col)
Vue.use(Timeline)
Vue.use(Tree)
Vue.use(Popover)
Vue.use(List)
Vue.use(Card)
Vue.use(Button)
Vue.use(Rate)
Vue.use(TreeSelect)
Vue.use(Breadcrumb)
Vue.use(Layout)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Progress)
Vue.use(Radio)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Input)
Vue.use(Badge)
Vue.use(Form)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Tag)
Vue.use(Affix)
Vue.use(Spin)
Vue.use(Alert)
Vue.use(Checkbox)
Vue.use(Tabs)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Message)
Vue.use(Steps)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Anchor)
Vue.use(AutoComplete)
Vue.use(LocaleProvider)



Vue.prototype.$bhapp = 'models'


Vue.prototype.$jwt = jwtDecode
Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$api = $api
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$bh = DBURL
Vue.prototype.$err = parseError

Vue.use(NProgress)
Vue.use(VuePageTitle, {
	prefix: 'Bildhive | ',
	router,
})
Vue.config.devtools = true
Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' });

// Disable Vue Devtools in production
if (process.env.NODE_ENV === 'production') {
	Vue.config.devtools = false
}

(async () => {
	await refreshToken(true)
	new Vue({
		router,
		store,
		nprogress,
		i18n,
		render: h => h(App),
	}).$mount('#app')
})()
