import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import models from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    models,
    library
  },
  state: {
    instances:[],
    instance:null,
    hives:[],
    cats:[],
    uploadDialog:false,
    folders:[],
    files:{},
    currentFolder:'/',
    theApp:{},
	fileStructure:[]
  },
  mutations: {
	SET_FILE_STRUCTURE(state, data) {
		state.fileStructure = data;
	},
    SET_APP(state,data){
      state.theApp = data
    },
    cancelUpload: (state) => {
      state.uploadDialog = false
    },
    uploadDialog: (state,folders = []) => {
      state.uploadDialog = true
	  state.folders = folders
    },
    SET_INSTANCE: (state,data) => {
      state.instance = data
      state.cats = data.activeApps
    },

  },


})
